<template>
  <div class="card p-shadow-6">
    <h1>Lista de Fichas de Entrevista</h1>
    <Button
          :label="'Nova Ficha de Entrevista'"
          icon="pi pi-plus"
          class="p-button-success"
          @click="viewInterview(0)"
        />
    <DataTable
      :value="entreviewList"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm p-mt-3"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords}"
      sortField="name"
      :sortOrder="1"
      :autoLayout="true"
      responsiveLayout="scroll"
    >
      <template #empty> Nada a mostrar. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <Column
        field="date_date"
        header="Data"
        sortable
        filterField="date_date"
        dataType="date"
      >
        <template #body="slotProps">
          {{ slotProps.data.date }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <v-date-picker
            v-model="filterModel.value"
            @input="filterCallback()"
            :masks="{
              input: 'YYYY-MM-DD'
            }"
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="p-column-filter p-inputtext p-component"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </template>
      </Column>
      <Column field="candidate" header="Candidato" sortable filterField="candidate">
        <template #body="slotProps">
          {{ slotProps.data.candidate }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="candidate_email" header="Email" sortable filterField="candidate_email">
        <template #body="slotProps">
          {{ slotProps.data.candidate_email }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column field="candidate_phone" header="Telefone" sortable filterField="candidate_phone">
        <template #body="slotProps">
          {{ slotProps.data.candidate_phone }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            class="p-column-filter"
            v-model="filterModel.value"
            @input="filterCallback()"
          />
        </template>
      </Column>
      <Column
        field="new_interview"
        header="Nova Entrevista"
        sortable
        filterField="new_interview"
        dataType="boolean"
      >
        <template #body="slotProps">
          {{ slotProps.data.new_interview ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' }
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="admission"
        header="Admitido"
        sortable
        filterField="admission"
        dataType="boolean"
      >
        <template #body="slotProps">
          {{ slotProps.data.admission ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' }
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>
      <Column
        field="archive"
        header="Arquivado"
        sortable
        filterField="archive"
        dataType="boolean"
      >
        <template #body="slotProps">
          {{ slotProps.data.archive ? "Sim" : "Não" }}
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            @input="filterCallback()"
            :options="[
              { value: false, label: 'Não' },
              { value: true, label: 'Sim' }
            ]"
            optionValue="value"
            optionLabel="label"
            class="p-column-filter"
            :showClear="true"
          >
          </Dropdown>
        </template>
      </Column>

      <Column>
        <template #body="slotProps">
          <Button
            v-tooltip.left="'Ver Ficha'"
            icon="pi pi-eye"
            class="p-button-rounded p-button-success p-button-outlined"
            @click="viewInterview(slotProps.data.id)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import interviewservice from "../services/interviewSheet.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "InterviewSheets",
  data() {
    return {
      loading: true,
      entreviewList: [],
      filters: {
        date_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        candidate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        candidate_email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        candidate_phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        new_interview: { value: null, matchMode: FilterMatchMode.EQUALS },
        admission: { value: null, matchMode: FilterMatchMode.EQUALS },
        archive: { value: null, matchMode: FilterMatchMode.EQUALS },
      }
    };
  },
  created() {
    this.loading = true;
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      return interviewservice.getUserInterviews().then(response => {
        response.forEach(element => {
          element["date_date"] = new Date(element.date);
          element["admission"] = element.admission ? true : false;
          element["admission_date"] = element.admission_date ? new Date(element.admission_date) : null;
          element["new_interview"] = element.new_interview ? true : false;
          element["archive"] = element.archive ? true : false;
          element["delete"] = element.delete ? true : false;
          this.entreviewList.push(element);
        });
        return (this.loading = false);
      });
    },
    viewInterview(id) {
      this.$router.push(`/interview-sheet-view/${id}`);
    }
  }
};
</script>
<style scoped lang="scss">
td {
  width: 1px;
  white-space: nowrap;
}


</style>
